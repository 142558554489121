/* cyrillic-ext */
@font-face {
  font-family: '__IBM_Plex_Sans_c7afa5';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/3763ada0a9bb1988-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__IBM_Plex_Sans_c7afa5';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/787b0654beea4191-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek */
@font-face {
  font-family: '__IBM_Plex_Sans_c7afa5';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/2c3c1f0838a71cfb-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__IBM_Plex_Sans_c7afa5';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/47bcda1e7b1ac8be-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__IBM_Plex_Sans_c7afa5';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/bed76354f405346d-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__IBM_Plex_Sans_c7afa5';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/c70aa70a01eaecff-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__IBM_Plex_Sans_Fallback_c7afa5';src: local("Arial");ascent-override: 101.32%;descent-override: 27.18%;line-gap-override: 0.00%;size-adjust: 101.17%
}.__className_c7afa5 {font-family: '__IBM_Plex_Sans_c7afa5', '__IBM_Plex_Sans_Fallback_c7afa5';font-weight: 200;font-style: normal
}

/* cyrillic-ext */
@font-face {
  font-family: '__IBM_Plex_Mono_c8df7a';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/ce562f20499ad585-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__IBM_Plex_Mono_c8df7a';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/57699fff66d5761c-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__IBM_Plex_Mono_c8df7a';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/e490b1ecb6589d48-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__IBM_Plex_Mono_c8df7a';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/524be5e37a616f9b-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__IBM_Plex_Mono_c8df7a';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/c690761783507ad4-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__IBM_Plex_Mono_Fallback_c8df7a';src: local("Arial");ascent-override: 76.16%;descent-override: 20.43%;line-gap-override: 0.00%;size-adjust: 134.59%
}.__className_c8df7a {font-family: '__IBM_Plex_Mono_c8df7a', '__IBM_Plex_Mono_Fallback_c8df7a';font-weight: 200;font-style: normal
}

/* cyrillic-ext */
@font-face {
  font-family: '__IBM_Plex_Sans_a284e1';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/24cfcc12c15b03b9-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__IBM_Plex_Sans_a284e1';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/29ffcd3df3f1cb91-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek */
@font-face {
  font-family: '__IBM_Plex_Sans_a284e1';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/3cef106cd115e360-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__IBM_Plex_Sans_a284e1';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/a0c95037dd5c9387-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__IBM_Plex_Sans_a284e1';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/e889cd181219a83f-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__IBM_Plex_Sans_a284e1';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/6d6d0e4a023f9f33-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__IBM_Plex_Sans_Fallback_a284e1';src: local("Arial");ascent-override: 101.32%;descent-override: 27.18%;line-gap-override: 0.00%;size-adjust: 101.17%
}.__className_a284e1 {font-family: '__IBM_Plex_Sans_a284e1', '__IBM_Plex_Sans_Fallback_a284e1';font-weight: 300;font-style: normal
}

/* cyrillic-ext */
@font-face {
  font-family: '__IBM_Plex_Mono_46981b';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/b6f2eee8808a2bb4-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__IBM_Plex_Mono_46981b';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/bc2003170c651d45-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__IBM_Plex_Mono_46981b';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/d43ef4503e5571d0-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__IBM_Plex_Mono_46981b';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/3281a323710833ec-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__IBM_Plex_Mono_46981b';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/684e5662d94c69e1-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__IBM_Plex_Mono_Fallback_46981b';src: local("Arial");ascent-override: 76.16%;descent-override: 20.43%;line-gap-override: 0.00%;size-adjust: 134.59%
}.__className_46981b {font-family: '__IBM_Plex_Mono_46981b', '__IBM_Plex_Mono_Fallback_46981b';font-weight: 500;font-style: normal
}

